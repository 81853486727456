// import React from "react";
// import Button$ from "@commerce-ui/core2/next/Button";
// import getLinkProps from "../getLinkProps";
// import {useRouter} from "next/router";
// import {getLangFromRouter} from "../../../helpers/setLang";
//
// export default React.forwardRef((props, ref) => {
//     const { href, as, ...restProps } = props;
//     const router = useRouter();
//
//     if (!href) {
//         return <Button$ {...props} ref={ref} />
//     }
//
//     return <Button$ ref={ref} {...restProps} {...getLinkProps(as, href, getLangFromRouter(router))}  />
// })


import React from "react";
import Button$ from "@commerce-ui/core2/Button";
import withNextLink from "../withNextLink";
const Button = withNextLink(Button$);
Button.styled = (...args) => withNextLink(Button$.styled(...args));

export default Button;
// export default Button$;
