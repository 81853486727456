// import {breakpoints} from '../components/theme'
const breakpoints = ["420px", "768px", "1024px", "1400px", "1900px"];

const putImageSizes = (arr) => {

  let sizes;

  sizes = `${arr[0]}`;

  breakpoints.forEach( (b, i) => {

    if (arr[i] === arr[i+1]) {return} // don't make useless breakpoints

    if (arr[i+1]) {
      sizes =  `(min-width: ${b}) ${arr[i+1]}, ${sizes}`
    }

  } );

  // console.log(sizes)

  return sizes
}

export default putImageSizes