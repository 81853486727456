import React from "react";
import styledBox from "../../styledBox";

const Wrapper = styledBox({
  position: 'relative',
  bg: props => props.objectFit ? null :'imageBg',
  width: props => props.objectFit ? '100%' : null,
  height: props => props.objectFit ? '100%' : null,
  pb: props => !props.objectFit ? props.ratio * 100 + "%" : null,
  "video" : {
    objectFit: props => props.objectFit,
    position: props => props.objectFit ? 'relative' : 'absolute',
    top: 0, left: 0,
    width: '100%',
    height: '100%'
  }
});

function Video({video, objectFit}) {

    return <Wrapper ratio={ video.sources[0].height / video.sources[0].width } objectFit={objectFit}>
        <video
          autoPlay
          muted
          loop
          playsInline
          title={video.alt}
          // ref={this.image}
          style={objectFit ? {objectFit: objectFit} : null}
          // onCanPlay={this.handleImageLoaded}
          // className={`i-${this.randomId}`}
          // draggable={this.props.draggable}
        >
            {
                video.sources.map(source => (
                    <source key={source.url} type={source.type} src={source.url}/>
                ))
            }
        </video>
    </Wrapper>
}

export default Video;
