import styledBox from "../../styledBox";
import IconClose from "../icons/IconClose";
import React from "react";
import Button from "@button"


const NeedHelpHeader = styledBox({
  display: 'grid',
  gridTemplateColumns: '60px 1fr 60px',
  gridTemplateRows: '60px'
})
const NeedHelpHeaderTitle = styledBox({textAlign: 'center', alignSelf: 'center', font: 'body01'})


const DialogHeader = ({title, close}) => {
  return <NeedHelpHeader>
    <div/>
    <NeedHelpHeaderTitle>
      {title}
    </NeedHelpHeaderTitle>
    <Button sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}} onClick={close}>
      <IconClose size={20}/>
    </Button>
  </NeedHelpHeader>
}


export default DialogHeader