import React from "react";
import Link$, { LinkRaw } from "@commerce-ui/core2/Link";
import withNextLink from "../withNextLink";

// import { useRouter } from "next/router";
// import getLinkProps from "../getLinkProps";
// import {getLangFromRouter} from "../../../helpers/setLang"
//
// export default React.forwardRef((props, ref) => {
//     const { href, as, ...restProps } = props;
//     const router = useRouter();
//
//     // console.log("### LINK getLang", getLang(), "### router.query.lang", router.query.lang)
//
//     return <Link$
//       ref={ref}
//       {...restProps}
//       {...getLinkProps(as, href, getLangFromRouter(router))}
//     />
// })


const Link = withNextLink(LinkRaw);
Link.styled = (...args) => withNextLink(Link$.styled(...args));

export default Link;
// export default Link$;
