const formatPrice = (price) => {

  let _amount = price.amount.replace('.0','').replace('.00','');

  if(price.currencyCode === "USD") {
    return `$${_amount}`
  }

  if(price.currencyCode === "EUR") {
    return `€${_amount}`
  }

  if (price.currencyCode === "PLN") {
    return `${_amount}zł`
  }

  if (price.currencyCode === "GBP"){
    return `£${_amount}`
  }

  return `${price.currencyCode}${_amount}`

};

export default formatPrice;
