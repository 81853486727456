import formatPrice from "../../helpers/formatPrice";
import React from "react";


const ProductPrice = ({product}) => {
  return product.compareAtPrice ?
    <>
      <s>&nbsp;{formatPrice(product.compareAtPrice)}&nbsp;</s> {formatPrice(product.price)}
    </>
    :
    formatPrice(product.price)
};


export default ProductPrice